import { createContext, useState, useContext, useEffect } from "react"

const CartContext = createContext()

export const AppProvider = ({ children }) => {
  const getInitailCartData = () => {
    const cartData = localStorage.getItem("cart-data")
    return cartData ? JSON.parse(cartData) : []
  }
  const [cartData, setCartData] = useState(getInitailCartData)

  useEffect(() => {
    localStorage.setItem("cart-data", JSON.stringify(cartData))
  }, [cartData])

  return (
    <CartContext.Provider value={{ cartData, setCartData }}>
      {children}
    </CartContext.Provider>
  )
}

export const useGlobalContext = () => useContext(CartContext)
