import { FaSnapchat, FaInstagram, FaWhatsapp, FaTiktok, FaPhoneAlt } from 'react-icons/fa'
import ReciveFromStore from '../components/ReciveFromStore'
import TableNumber from '../components/TableNumber'
import Transport from '../components/Transport'

export const sideNavData = [
  {
    id: 1,
    text: 'سناب شات',
    icon: FaSnapchat,
    href: 'https://www.snapchat.com/add/zmlounge',
  },
  {
    id: 2,
    text: "تيك توك",
    icon: FaTiktok,
    href: "https://www.tiktok.com/@zmlounge",
  },
  {
    id: 3,
    text: 'إنستجرام',
    icon: FaInstagram,
    href: 'https://www.instagram.com/zmlounge',
  },
  {
    id: 4,
    text: 'واتساب',
    icon: FaWhatsapp,
    href: 'https://api.whatsapp.com/send/?phone=966593482358',
  },
  { id: 5, text: 'اتصل بنا', icon: FaPhoneAlt, href: 'tel:0505574666' },
]
export const data = [
   {
        id: 1,
        title: 'القهوة الساخنة',
        image: '../../../img/list/coffe-cup.png',
        selected: true,
        items: [
            {
                id: 1,
                title: 'سبانش لاتيه',
                description: '',
                image: '../../img/food/span.jpg',
                price: 15,
                calories: 209,
                selected: false,
            },
            {
                id: 2,
                title: 'بستاشيو لاتيه',
                description: '',
                image: '../../img/food/pstachio.jpg',
                price: 15,
                calories: 180,
                selected: false,
            },
            {
                id: 3,
                title: 'زعفران لاتيه',
                description: '',
                image: '../../img/food/zafran.jpg',
                price: 15,
                calories: 206,
                selected: true,
            },
            {
                id: 4,
                title: 'كابتشينو',
                description: '',
                image: '../../img/food/capatchino.jpg',
                price: 15,
                calories: 144,
                selected: true,
            },
            {
                id: 5,
                title: 'لاتية',
                description: '',
                image: '../../img/food/late.jpg',
                price: 14,
                calories: 151,
                selected: true,
            },
            {
                id: 6,
                title: 'فلات وايت',
                description: '',
                image: '../../img/food/falt.jpg',
                price: 14,
                calories: 131,
                selected: true,
            },
            {
                id: 7,
                title: 'موكا',
                description: '',
                image: '../../img/food/moka.jpg',
                price: 15,
                calories: 215,
                selected: true,
            },
            {
                id: 8,
                title: 'أمريكانو',
                description: '',
                image: '../../img/food/amrico.jpg',
                price: 11,
                calories: 12,
                selected: true,
            },
            {
                id: 9,
                title: 'ماكياتو',
                description: '',
                image: '../../img/food/maciato.jpg',
                price: 15,
                calories: 90,
                selected: true,
            },
            {
                id: 10,
                title: 'إطاليانو',
                description: '',
                image: '../../img/food/italiano.jpg',
                price: 15,
                calories: 189,
                selected: true,
            },
            {
                id: 11,
                title: 'كورتادو',
                description: '',
                image: '../../img/food/cortado.jpg',
                price: 16,
                calories: 162,
                selected: true,
            },
            {
                id: 12,
                title: 'إسبرسو',
                description: '',
                image: '../../img/food/ispressop.jpg',
                price: 8,
                calories: 4,
                selected: true,
            },
            {
                id: 13,
                title: 'دبل إسبرسو',
                description: '',
                image: '../../img/food/ispressop.jpg',
                price: 12,
                calories: 8,
                selected: true,
            },
            {
                id: 14,
                title: 'قهوة تركية',
                description: '',
                image: '../../img/food/turkish.jpg',
                price: 7,
                calories: 5,
                selected: true,
            },
            {
                id: 15,
                title: 'قهوة فرنسية',
                description: '',
                image: '../../img/food/frnch.jpg',
                price: 14,
                calories: 7,
                selected: true,
            },
            {
                id: 16,
                title: 'دلة قهوة سعودية',
                description: '',
                image: '../../img/food/dlah.jpg',
                price: 20,
                calories: 5,
                selected: true,
            },
        ]
    },
    {
        id: 2,
        title: 'المشروبات الباردة',
        image: '../../../img/list/bard.png',
        selected: false,
        items: [
            {
                id: 17,
                title: 'ايس سبانش لاتيه',
                description: '',
                image: '../../img/food/spanish.jpg',
                price: 15,
                calories: 206,
                selected: false,
            },
            {
                id: 18,
                title: 'ايس بستاشيو لاتيه',
                description: '',
                image: '../../img/food/bstachio.jpg',
                price: 15,
                calories: 250,
                selected: false,
            },
            {
                id: 19,
                title: 'ايس زعفران لاتيه',
                description: '',
                image: '../../img/food/saffron.jpg',
                price: 15,
                calories: 206,
                selected: true,
            },
            {
                id: 20,
                title: 'ايس كراميل لاتيه',
                description: '',
                image: '../../img/food/caramel.jpg',
                price: 15,
                calories: 250,
                selected: true,
            },
            {
                id: 21,
                title: 'ايس فانيلا لاتيه',
                description: '',
                image: '../../img/food/fanila.jpg',
                price: 15,
                calories: 227,
                selected: true,
            },
            {
                id: 22,
                title: ' ايس وايت لاتية',
                description: '',
                image: '../../img/food/icemoca.jpg',
                price: 15,
                calories: 240,
                selected: true,
            },
            {
                id: 23,
                title: 'ايس موكا',
                description: '',
                image: '../../img/food/icemoca.jpg',
                price: 15,
                calories: 250,
                selected: true,
            },
            {
                id: 24,
                title: 'ايس أمريكانو',
                description: '',
                image: '../../img/food/iceamricano.jpg',
                price: 15,
                calories: 15,
                selected: true,
            },
            {
                id: 25,
                title: 'ايس ريد فيلفيت',
                description: '',
                image: '../../img/food/redf.jpeg',
                price: 15,
                calories: 204,
                selected: true,
            },
            {
                id: 26,
                title: 'ايس شوكلت',
                description: '',
                image: '../../img/food/icemoca.jpg',
                price: 15,
                calories: 214,
                selected: true,
            },
            {
                id: 27,
                title: 'ايس إيطاليانو',
                description: '',
                image: '../../img/food/icemoca.jpg',
                price: 15,
                calories: 227,
                selected: true,
            },
            {
                id: 28,
                title: 'ايس توفي لاتيه',
                description: '',
                image: '../../img/food/caramel.jpg',
                price: 15,
                calories: 246,
                selected: true,
            },
        ]
    },
    {
        id: 3,
        title: 'الشاي',
        image: '../../../img/list/tea.png',
        selected: false,
        items: [
            {
                id: 29,
                title: 'شاي احمر',
                description: 'كوب - براد',
                image: '../../img/food/shahya7mr.png',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
            {
                id: 30,
                title: 'شاي اخضر',
                description: 'كوب - براد',
                image: '../../img/food/shahya5dr.png',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
            {
                id: 31,
                title: 'شاي مغربي',
                description: 'كوب - براد',
                image: '../../img/food/mghrbi.png',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
            {
                id: 32,
                title: 'شاي تلقيمة',
                description: 'كوب - براد',
                image: '../../img/food/englishtea.png',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
            {
                id: 33,
                title: 'شاي رمان',
                description: 'كوب - براد',
                image: '../../img/food/roman.png',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
            {
                id: 34,
                title: 'شاي كرك',
                description: 'كوب - براد',
                image: '../../img/food/6c8.jpg',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
            {
                id: 35,
                title: 'شاي يانسون',
                description: 'كوب - براد',
                image: '../../img/food/yansuon.jpeg',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
            {
                id: 36,
                title: 'شاي بابونج',
                description: 'كوب - براد',
                image: '../../img/food/babonj.jpeg',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
            {
                id: 37,
                title: 'شاي توت',
                description: 'كوب - براد',
                image: '../../img/food/tea-tot.jpg',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
            {
                id: 38,
                title: 'كركديه',
                description: 'كوب - براد',
                image: '../../img/food/krkadia.jpg',
                price: 5,
                calories: 0,
                selected: false,
                size: {
                    small: 5,
                    large: 15,
                  },
            },
        ]
    },
    {
        id: 4,
        title: 'القهوة المختصة',
        image: '../../../img/list/mo5.png',
        selected: false,
        items: [
            {
                id: 39,
                title: 'فرنش بريس',
                description: '',
                image: '../../img/food/frnch.jpeg',
                price: 15,
                calories: 0,
                selected: false,
            },
            {
                id: 40,
                title: 'كيمكس',
                description: '',
                image: '../../img/food/kimks.jpeg',
                price: 15,
                calories: 0,
                selected: false,
            },
            {
                id: 41,
                title: 'V60',
                description: '',
                image: '../../img/food/v60.jpeg',
                price: 15,
                calories: 0,
                selected: true,
            },
        ]
    },
    {
        id: 5,
        title: 'الموهيتو',
        image: '../../../img/list/mohi.png',
        selected: false,
        items: [
            {
                id: 42,
                title: 'رد بيري',
                description: '',
                image: '../../img/food/specialmohito.jpeg',
                price: 18,
                calories: 428,
                selected: false,
            },
            {
                id: 43,
                title: 'بلو بيري',
                description: '',
                image: '../../img/food/redperry.jpg',
                price: 18,
                calories: 319,
                selected: false,
            },
            {
                id: 44,
                title: 'روز بيري',
                description: '',
                image: '../../img/food/rasberrymohito.jpeg',
                price: 18,
                calories: 439,
                selected: true,
            },
            {
                id: 45,
                title: 'سبيشل موهيتو',
                description: '',
                image: '../../img/food/specialmohito.jpeg',
                price: 19,
                calories: 435,
                selected: true,
            },
            {
                id: 46,
                title: 'اليرس',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 310,
                selected: true,
            },
            {
                id: 47,
                title: 'بلودي ميري',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 310,
                selected: true,
            },
            {
                id: 48,
                title: 'ميما كولادا',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 310,
                selected: true,
            },
            {
                id: 49,
                title: 'مير بيري موهيتو',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 443,
                selected: true,
            },
            {
                id: 50,
                title: 'ماماريتا موهيتو',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 443,
                selected: true,
            },
            {
                id: 51,
                title: 'بلواوسيان موهيتو',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 443,
                selected: true,
            },
        ]
    },
    {
        id: 6,
        title: 'الحلا',
        image: '../../../img/list/soit.png',
        selected: false,
        items: [
            {
                id: 52,
                title: 'حلا زاوية المزاج',
                description: '',
                image: '../../img/items.png',
                price: 20,
                calories: 580,
                selected: false,
            },
            {
                id: 53,
                title: 'كريب ايس كريم',
                description: '',
                image: '../../img/items.png',
                price: 25,
                calories: 590,
                selected: false,
            },
        ]
    },
    {
        id: 7,
        title: 'العصائر',
        image: '../../../img/list/juc.png',
        selected: false,
        items: [
            {
                id: 54,
                title: 'سندريلا',
                description: '',
                image: '../../img/food/tofa7.png',
                price: 13,
                calories: 115,
                selected: false,
            },
            {
                id: 55,
                title: 'برتقال',
                description: '',
                image: '../../img/food/bort8al.png',
                price: 13,
                calories: 55,
                selected: true,
            },
            {
                id: 56,
                title: 'ليمون سادة',
                description: '',
                image: '../../img/food/limon3.png',
                price: 13,
                calories: 190,
                selected: true,
            },
            {
                id: 57,
                title: 'اناناس',
                description: '',
                image: '../../img/food/ananas.png',
                price: 13,
                calories: 150,
                selected: true,
            },
            {
                id: 58,
                title: 'باشن',
                description: '',
                image: '../../img/food/koktil.png',
                price: 13,
                calories: 560,
                selected: true,
            },
            {
                id: 59,
                title: 'جامايكا',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 108,
                selected: false,
            },
            {
                id: 60,
                title: 'بنكلي وادا',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 114,
                selected: false,
            },
        ]
    },
    {
        id: 8,
        title: 'السموزي',
        image: '../../../img/list/smoth.png',
        selected: false,
        items: [
            {
                id: 61,
                title: 'ليمون نعناع',
                description: '',
                image: '../../img/items.png',
                price: 17,
                calories: 102,
                selected: false,
            },
            {
                id: 62,
                title: 'فراولة',
                description: '',
                image: '../../img/items.png',
                price: 17,
                calories: 108,
                selected: false,
            },
            {
                id: 63,
                title: 'روز بيري',
                description: '',
                image: '../../img/items.png',
                price: 17,
                calories: 108,
                selected: true,
            },
            {
                id: 64,
                title: 'باشن',
                description: '',
                image: '../../img/items.png',
                price: 17,
                calories: 250,
                selected: true,
            },
        ]
    },
    {
        id: 9,
        title: 'مشروبات اخرى',
        image: '../../../img/list/energy.png',
        selected: false,
        items: [
            {
                id: 65,
                title: 'ريدبول',
                description: '',
                image: '../../img/food/redbull.jpg',
                price: 20,
                calories: 210,
                selected: false,
            },
            {
                id: 66,
                title: 'بيبسي',
                description: '',
                image: '../../img/food/pebsi.jpg',
                price: 5,
                calories: 183,
                selected: false,
            },
            {
                id: 67,
                title: 'سفن اب',
                description: '',
                image: '../../img/food/sfn.jpeg',
                price: 5,
                calories: 184,
                selected: true,
            },
            {
                id: 68,
                title: ' ماء معدني',
                description: '',
                image: '../../img/food/ber.jpg',
                price: 3,
                calories: 0,
                selected: true,
            },
            {
                id: 69,
                title: ' مياة نوفا زجاج',
                description: '',
                image: '../../img/food/foar.jpg',
                price: 7,
                calories: 0,
                selected: true,
            },
        ]
    },
    {
        id: 10,
        title: 'الشيشة',
        image: '../../../img/list/shisha.png',
        selected: false,
        items: [
            {
                id: 70,
                title: 'عنب نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: false,
            },
            {
                id: 71,
                title: 'عنب فرنسي',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: false,
            },
            {
                id: 72,
                title: 'ليمون نعناع فاخر',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 73,
                title: 'ليمون نعناع مزايا',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 74,
                title: 'شمام كنتلوب',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 75,
                title: 'بلو بيري',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 76,
                title: 'مستكة نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 77,
                title: 'علكة مستكة',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 78,
                title: 'بطيخ نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 79,
                title: 'علكة ساده',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 80,
                title: 'شيشة تفاحتين فاخر',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 81,
                title: 'مكس',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 82,
                title: 'شيشة تفاحتين نخله',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 83,
                title: 'شيشة ليمون نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 84,
                title: 'شيشة عنب نعناع',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 85,
                title: 'شيشة عنب توت',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
            {
                id: 86,
                title: 'شيشة عنب سادة',
                description: '',
                image: '../../img/food/Shisha.jpg',
                price: 35,
                calories: 0,
                selected: true,
            },
        ]
    }, 
]
export const deliveryOptions = [
  {
    id: 1,
    component: Transport,
    name: 'توصيل',
    img: '../../img/delivery/delivery.png',
  },
  {
    id: 2,
    component: ReciveFromStore,
    name: 'إستلام',
    img: '../../img/delivery/pickup.png',
  },
  {
    id: 3,
    component: TableNumber,
    name: 'إختيار الطاولة',
    img: '../../img/delivery/number.png',
  },
]
