import { useGlobalContext } from '../context'
import CartItem from '../components/CartItem'
import { IoArrowBackCircle } from 'react-icons/io5'
import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useLockBodyScroll } from '@uidotdev/usehooks'

const CartPage = ({ quantity, setQuantity, setModalOn, setItemOn }) => {
  const { cartData, percentage } = useGlobalContext()
  const navigate = useNavigate()
  useLockBodyScroll()

  const total = cartData.reduce((acc, item) => acc + item.price, 0)

  const handleDelivery = () => {
    setModalOn(false)
    if (total < 0) toast.error('الحد الأدنى للتوصيل هو 20 ريال سعودي')
    else {
      navigate('/Delivery')
    }
  }

  return (
    <div
      dir='rtl'
      className='fixed overflow-y-auto inset-x-0 max-w-md md:ml-auto md:mr-0 mx-auto h-full bg-white dark:bg-gray-700 overflow-x-hidden w-full z-[401] fastAnimate'
    >
      <div className='z-50 fixed w-full bg-white max-w-md mx-auto h-16 top-auto shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid justify-start items-center'>
          <div
            onClick={() => setModalOn(false)}
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className=' w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className=' w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 mx-0.5 text-brown-400'>
                سلة الطلبات
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Order Basket
              </span>
            </h1>
          </div>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-main-900 hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={() => setModalOn(false)}
        />
      </div>
      <div className='w-full pt-20 px-4 flex flex-col gap-4 items-center justify-end bg-white dark:bg-gray-700'>
        {cartData.length > 0 ? (
          cartData.map((item, i) => (
            <CartItem
              key={i}
              item={item}
              quantity={quantity}
              setQuantity={setQuantity}
              setModalOn={setModalOn}
              setItemOn={setItemOn}
              setSingleItem={setItemOn}
            />
          ))
        ) : (
          <div className='w-full flex items-center font-semibold gap-4 justify-center flex-col my-20 dark:text-white'>
            <img
              src='../../img/add-to-basket.png'
              alt='add to basket icon'
              width='100em'
              height='100em'
            />
            <h2 className='text-3xl text-center text-main-900 dark:text-white'>
              السلة فارغة
            </h2>
            <button
              onClick={() => setModalOn(false)}
              className='w-full font-semibold flex items-center justify-center text-main-900 gap-2 border-2 border-main-900 rounded-full py-2 px-4 w-full dark:bg-gray-900 dark:text-white dark:border-[#111827]'
            >
              <IoArrowBackCircle className='text-2xl icon-flip' /> رجوع
            </button>
          </div>
        )}
      </div>
      {cartData.length > 0 ? (
        <div className='flex flex-col my-6 justify-start px-4'>
          <div className='flex items-center justify-between foa9l'>
            <p className='border-main-900 text-main-900 dark:text-white text-lg font-semibold'>
              المجموع
            </p>
            <div className='flex items-center font-semibold gap-2 dark:text-white'>
              <span className='text-main-900 dark:text-white text-lg font-semibold'>
                {total.toLocaleString('en-US')}
              </span>
              ريال
            </div>
          </div>
          {percentage ? (
            <>
              <div className='flex items-center justify-between p-[5px] border-b border-dashed border-[#ccc]'>
                <p className='border-main-900 text-main-900 dark:text-white text-lg font-semibold'>
                  الخصم ({percentage}%)
                </p>
                <div className='flex items-center font-semibold gap-2 dark:text-white'>
                  <span className='text-main-900 dark:text-white text-lg font-semibold'>
                    {((percentage / 100) * total).toLocaleString('en-US')}
                  </span>
                  ريال
                </div>
              </div>
              <div className='flex items-center justify-between p-[5px]'>
                <p className='border-main-900 text-main-900 dark:text-white text-lg font-semibold'>
                  المجموع بعد الخصم
                </p>
                <div className='flex items-center font-semibold gap-2 dark:text-white'>
                  <span className='text-main-900 dark:text-white text-lg font-semibold'>
                    {(total - (percentage / 100) * total).toLocaleString(
                      'en-US'
                    )}
                  </span>
                  ريال
                </div>
              </div>{' '}
            </>
          ) : null}
          <div className='font-semibold flex flex-col gap-4 mt-4'>
            <button
              onClick={handleDelivery}
              className='py-2 w-full rounded-full bg-main-900 text-white text-center'
            >
              التالي
            </button>
            <button
              onClick={() => setModalOn(false)}
              className='w-full font-semibold flex items-center justify-center text-main-900 gap-2 border-2 border-main-900 rounded-full py-2 px-4 w-full dark:bg-gray-900 dark:text-white dark:border-[#111827]'
            >
              <IoArrowBackCircle className='text-2xl icon-flip' /> رجوع
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CartPage
