import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import LeafletGeocoder from './LeafletGeocoder'
import marker from '../img/marker.svg'
import 'leaflet-control-geocoder/dist/Control.Geocoder.css'
import 'leaflet-control-geocoder/dist/Control.Geocoder.js'

const Map = ({ userLatLng, setUserLatLng, resLatLng, setResLatLng }) => {
  const center = [24.590903, 46.579553]
  return (
    <MapContainer
      center={center}
      zoom={15}
      attributionControl={false}
      className='delivery'
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
      />
      <Marker position={center}></Marker>
      <LeafletGeocoder
        userLatLng={userLatLng}
        setUserLatLng={setUserLatLng}
        resLatLng={resLatLng}
        setResLatLng={setResLatLng}
      />
    </MapContainer>
  )
}

const DefaultIcon = L.icon({
  iconUrl: marker,
  iconSize: 40,
  iconAnchor: [10, 41],
  popupAnchor: [2, 40],
})
L.Marker.prototype.options.icon = DefaultIcon
export default Map
