import { useEffect, useState } from 'react'
import Map from './Map'
import Modal from './Modal'
import { MdLocationOn } from 'react-icons/md'

const Transport = ({ swiper }) => {
  const [formErrors, setFormErrors] = useState({})
  const [, setFormData] = useState({})
  const [userLatLng, setUserLatLng] = useState({ lat: 0, lng: 0 })
  const [modalOn, setModalOn] = useState(false)

  const calcCrow = (lat1, lon1, lat2, lon2) => {
    var R = 6371 // km
    var dLat = toRad(lat2 - lat1)
    var dLon = toRad(lon2 - lon1)
    var latitude1 = toRad(lat1)
    var latitude2 = toRad(lat2)

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(latitude1) *
        Math.cos(latitude2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
  }
  const toRad = (Value) => {
    return (Value * Math.PI) / 180
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'هذا الحقل مطلوب'
    }
    if (!values.address) {
      errors.address = 'هذا الحقل مطلوب'
    }
    if (!values.phone) {
      errors.phone = 'هذا الحقل مطلوب'
    }
    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      name: e.target.name.value,
      address: e.target.address.value,
      phone: e.target.phone.value,
    }
    setFormErrors(validate(data))
    setFormData(data)
    e.target.reset()
    if (userLatLng.lat === 0) {
      alert('قم بالسماح بتحديد الموقع لتحديد موقعك آلياً')
    } else {
      const distance = calcCrow(
        24.43265,
        39.6536,
        userLatLng.lat,
        userLatLng.lng
      )
      if (distance < 1000) {
        alert('عفواً، لا نستطيع التوصيل لأي موقع أبعد من 1000 كيلومترات')
      } else {
        swiper.slideTo(2)
      }
    }
  }

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (location) {
          const { latitude, longitude } = location.coords
          setUserLatLng({ lat: latitude, lng: longitude })
        } else {
          alert('قم بالسماح بتحديد الموقع لتحديد موقعك آلياً')
        }
      })
    }
  }, [])

  return (
    <div className='relative h-[calc(100vh-136px)] w-full overflow-hidden flex flex-col'>
      <Map userLatLng={userLatLng} setUserLatLng={setUserLatLng} />
      <form
        className='absolute bottom-0 w-full flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white animateItems'
        onSubmit={handleSubmit}
      >
        <div className='flex flex-col text-sm'>
          <label htmlFor='name' className='relative'>
            <input
              type='text'
              required
              id='name'
              name='name'
              className={`outline-none peer border-2 focus:border-main-900 w-full h-10 mt-1 px-4 rounded-full ${
                formErrors?.name ? 'border-red-500' : ''
              }`}
            />
            <span className='peer-focus:text-main-900 hidden peer-invalid:inline peer-focus:-translate-y-5 peer-focus:translate-x-2 peer-focus:scale-75 absolute text-black/50 right-0 top-3.5 mx-4 px-0.5 bg-white z-20 transition duration-200'>
              الاسم
            </span>
          </label>
        </div>
        <div className='flex flex-col text-sm'>
          <label htmlFor='address' className='relative'>
            <input
              type='text'
              required
              id='address'
              name='address'
              className={`outline-none peer border-2 focus:border-main-900 w-full h-10 mt-1 px-4 rounded-full ${
                formErrors?.address ? 'border-red-500' : ''
              }`}
            />
            <span className='peer-focus:text-main-900 hidden peer-invalid:inline peer-focus:-translate-y-5 peer-focus:translate-x-2 peer-focus:scale-75 absolute text-black/50 right-0 top-3.5 mx-4 px-0.5 bg-white z-20 transition duration-200'>
              العنوان
            </span>
          </label>
        </div>
        <div className='flex flex-col text-sm'>
          <label dir='ltr' htmlFor='phone' className='relative'>
            <input
              type='number'
              dir='rtl'
              required
              id='phone'
              name='phone'
              autoComplete='off'
              className={`outline-none peer border-2 focus:border-main-900 w-full h-10 mt-1 px-4 rounded-full ${
                formErrors?.phone ? 'border-red-500' : ''
              }`}
            />
            <span className='peer-focus:text-main-900 hidden peer-invalid:inline peer-focus:-translate-y-5 peer-focus:translate-x-2 peer-focus:scale-75 absolute text-black/50 right-0 top-3.5 mx-4 px-0.5 bg-white z-20 transition duration-200'>
              رقم الجوال
            </span>
          </label>
        </div>
        <button className='bg-main-900 text-white py-2 w-full rounded-full'>
          التالي
        </button>
      </form>
      {modalOn ? (
        <Modal setModalOn={setModalOn} title='عفواً'>
          <div className='flex items-center justify-center text-center flex-col gap-4'>
            <MdLocationOn className='text-5xl text-main-900' />
            <p>عفواً، لا نستطيع التوصيل لأي موقع أبعد من 1000 كيلومترات</p>
            <button onClick={() => setModalOn(false)}>إغلاق</button>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}
export default Transport
