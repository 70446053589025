// import { Link } from 'react-router-dom'
import { IoRemoveCircleOutline, IoAddCircleOutline } from 'react-icons/io5'
import { FiX } from 'react-icons/fi'
import { useGlobalContext } from '../context'
import { useState } from 'react'

const CartItem = ({ item, setModalOn, setItemOn, setSingleItem }) => {
  const { setCartData } = useGlobalContext()
  const [oldPrice] = useState(item.price / item.quantity)
  const add = () => {
    setCartData((prev) => {
      ++item.quantity
      item.price = oldPrice * item.quantity
      return [...prev]
    })
  }
  const remove = () => {
    if (item.quantity === 1) return
    setCartData((prev) => {
      --item.quantity
      item.price = oldPrice * item.quantity
      return [...prev]
    })
  }

  const removeItem = (id, size, extras, edits) => {
    setCartData((prev) => {
      return prev.filter((item) => {
        if (
          item.id !== id ||
          item.size.name !== size ||
          item.extra
            .map((e) => e.name)
            .sort()
            .toString() !== extras.sort().toString() ||
          item.edits
            .map((e) => e)
            .sort()
            .toString() !== edits.sort().toString()
        ) {
          return true
        }
        return false
      })
    })
  }

  const itemDetails = () => {
    setModalOn(false)
    setItemOn(true)
    setSingleItem(item)
  }

  return (
    <div className='w-full'>
      <div className='w-full h-28 rounded-lg grid grid-cols-12 gap-2 cursor-pointer bg-gray-100 dark:bg-gray-900'>
        <div className='relative w-full rounded-lg col-span-4 sm:col-span-3 flex items-center justify-center'>
          <div onClick={() => setModalOn(false)}>
            <img
              className='absolute inset-0 w-full h-full p-0.5 object-cover rounded-lg'
              src={item.img}
              alt='item img'
            />
          </div>
        </div>
        <div className='w-full relative col-span-8 sm:col-span-9 space-y-1 sm:space-y-2 px-2 flex flex-col justify-between'>
          <div
            onClick={itemDetails}
            className='mt-2 text-sm text-[#597C80] font-semibold  dark:text-white'
          >
            {item.name}
          </div>
          <div
            onClick={() => setModalOn(false)}
            className='flex flex-col gap-1'
          >
            {item.size.name.length > 0 ? (
              <p className='text-xs text-gray-800 dark:text-gray-400 overflow-hidden'>
                الحجم :
                {item.size.name === 'small'
                  ? ' كوب'
                  : item.size.name === 'medium'
                  ? ' وسط'
                  : ' براد'}
              </p>
            ) : (
              <p className='text-xs text-gray-800 dark:text-gray-400 overflow-hidden'>
                {item.description}
              </p>
            )}
            {/* {item.extra.length > 0 ? (
            <ul className='text-xs flex items-center flex-wrap gap-2 list-inside list-disc'>
              الاضافات :
              {item.extra.map((e, i) => (
                <li
                  key={i}
                  className='text-xs text-gray-800 dark:text-gray-400 whitespace-nowrap'
                >
                  {e.name}
                </li>
              ))}
            </ul>
          ) : null} */}
          </div>
          <div className='flex justify-between py-2 items-center w-full'>
            {item.calories ? (
              <span className='text-xs text-gray-800 dark:text-gray-400 '>
                <span className='text-xs mx-0.5'>سعرات حرارية :</span>
                {item.calories}
              </span>
            ) : (
              ''
            )}
            <div className='flex items-center justify-center gap-2 dark:text-white select-none'>
              <IoRemoveCircleOutline
                className={`text-3xl ${
                  item.quantity === 1
                    ? 'text-[#0000004d] dark:text-[#9ca3af]'
                    : 'text-[#597C80]'
                } cursor-pointer selected-none`}
                onClick={remove}
              />
              <span>{item.quantity}</span>
              <IoAddCircleOutline
                className='text-3xl text-[#597C80] cursor-pointer'
                onClick={add}
              />
            </div>
            <span className='text-sm flex items-center font-semibold self-end mr-auto dark:text-white'>
              {item.price ? item.price.toLocaleString('en-US') : null}
              <span className='text-primaryGreen-500 dark:text-white text-xs font-semibold mx-0.5'>
                ريال
              </span>
            </span>
            <FiX
              className='w-5 absolute -top-2 -left-2 h-5 p-0.5 rounded-full bg-red-600 text-gray-50 hover:bg-opacity-100 opacity-80 dark:bg-red-600 dark:text-gray-50 block transform hover:rotate-180 cursor-pointer transition duration-300 ease'
              onClick={() =>
                removeItem(
                  item.id,
                  item.size.name,
                  item.extra.map((e) => e.name),
                  item.edits.map((e) => e)
                )
              }
            />
          </div>
        </div>
      </div>
      {item.extra.length > 0 ? (
        <ul className='text-xs flex items-center flex-wrap gap-2 w-[95%] mx-auto py-[.2rem] justify-center text-center rounded-bl-lg rounded-br-lg px-2 bg-main-900 text-white'>
          الاضافات :
          {item.extra.map((e, i) => (
            <li key={i} className='text-xs text-white whitespace-nowrap'>
              {e.name}
            </li>
          ))}
        </ul>
      ) : null}
      {item.edits.length > 0 ? (
        <ul className='text-xs flex items-center flex-wrap gap-2 w-[90%] mx-auto py-[.2rem] justify-center text-center rounded-bl-lg rounded-br-lg px-2 bg-main-100 text-gray-900 dark:text-white'>
          التعديلات :
          {item.edits.map((e, i) => (
            <li
              key={i}
              className='text-[.68rem] text-gray-900 whitespace-nowrap dark:text-white'
            >
              {e}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

export default CartItem
